import {AiOutlineFundProjectionScreen} from 'react-icons/ai';
import type {Output} from 'sanity-typed-schema';
import {defineField} from 'sanity-typed-schema';

import {layout} from '@/sanity/schemas/primitives/layout';

export const projectListAll = () =>
	defineField({
		name: 'projectListAll',
		title: 'Prosjektliste, alle',
		icon: AiOutlineFundProjectionScreen,
		type: 'object',
		fields: [layout()],
		preview: {
			prepare: () => ({
				title: 'Prosjektliste, alle',
			}),
		},
		groups: [
			{
				name: 'content',
				title: 'Innhold',
				default: true,
			},
			{
				name: 'layout',
				title: 'Layout',
			},
		],
	});

export type ProjectListAllSchema = Output<typeof projectListAll>;
