import {RiArticleLine} from 'react-icons/ri';
import type {Rule} from 'sanity';
import type {Output} from 'sanity-typed-schema';
import {defineField} from 'sanity-typed-schema';

import {layout} from '@/sanity/schemas/primitives/layout';

export const articleListSelection = () =>
	defineField({
		name: 'articleListSelection',
		title: 'Artikkelliste, utvalg',
		type: 'object',
		icon: RiArticleLine,
		fields: [title(), tagline(), articles(), layout()],
		preview: {
			select: {
				title: 'title',
			},
			prepare: (select) => ({
				title: select.title ?? 'Mangler overskrift...',
				subtitle: 'Artikkelliste, utvalg',
			}),
		},
		groups: [
			{
				name: 'content',
				title: 'Innhold',
				default: true,
			},
			{
				name: 'layout',
				title: 'Layout',
			},
		],
	});

const title = () =>
	defineField({
		name: 'title',
		title: 'Overskrift',
		description: 'Overskrift for denne innholdsblokken.',
		type: 'string',
		group: 'content',
	});

const tagline = () =>
	defineField({
		name: 'tagline',
		title: 'Tekst',
		description: 'En valgfri tekst som står til overskriften.',
		type: 'string',
		group: 'content',
	});

const articles = () =>
	defineField({
		name: 'articles',
		title: 'Artikler',
		description: 'Artiklene som skal vises i artikkellisten.',
		type: 'array',
		group: 'content',
		of: [{type: 'reference', to: [{type: 'article'}]}],
		validation: (Rule: Rule) => Rule.required(),
	});

export type ArticleListSelectionSchema = Output<typeof articleListSelection>;
