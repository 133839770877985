import {AiOutlineFundProjectionScreen} from 'react-icons/ai';
import type {Rule} from 'sanity';
import type {Output} from 'sanity-typed-schema';
import {defineField} from 'sanity-typed-schema';

import {layout} from '@/sanity/schemas/primitives/layout';

export const projectListSelection = () =>
	defineField({
		name: 'projectListSelection',
		title: 'Prosjektliste, utvalg',
		type: 'object',
		icon: AiOutlineFundProjectionScreen,
		fields: [title(), tagline(), projects(), layout()],
		preview: {
			select: {
				title: 'title',
			},
			prepare: (select) => ({
				title: select.title ?? 'Mangler overskrift...',
				subtitle: 'Prosjektliste, utvalg',
			}),
		},
		groups: [
			{
				name: 'content',
				title: 'Innhold',
				default: true,
			},
			{
				name: 'layout',
				title: 'Layout',
			},
		],
	});

const title = () =>
	defineField({
		name: 'title',
		title: 'Overskrift',
		type: 'string',
		group: 'content',
	});

const tagline = () =>
	defineField({
		name: 'tagline',
		title: 'Undertittel',
		type: 'string',
		group: 'content',
	});

const projects = () =>
	defineField({
		name: 'projects',
		title: 'Prosjekter',
		type: 'array',
		group: 'content',
		of: [{type: 'reference', to: [{type: 'project'}]}],
		validation: (Rule: Rule) => Rule.required(),
	});

export type ProjectListSelectionSchema = Output<typeof projectListSelection>;
