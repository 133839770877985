import {RiTeamLine} from 'react-icons/ri';
import type {Rule} from 'sanity';
import type {Output} from 'sanity-typed-schema';
import {defineField} from 'sanity-typed-schema';

import {layout} from '@/sanity/schemas/primitives/layout';

export const personListSelection = () =>
	defineField({
		name: 'personListSelection',
		title: 'Personliste, utvalg',
		type: 'object',
		icon: RiTeamLine,
		fields: [title(), tagline(), persons(), layout()],
		preview: {
			select: {
				title: 'title',
			},
			prepare: (select) => ({
				title: select.title ?? 'Mangler overskrift...',
				subtitle: 'Personliste, utvalg',
			}),
		},
		groups: [
			{
				name: 'content',
				title: 'Innhold',
				default: true,
			},
			{
				name: 'layout',
				title: 'Layout',
			},
		],
	});

const title = () =>
	defineField({
		name: 'title',
		title: 'Overskrift',
		type: 'string',
		group: 'content',
	});

const tagline = () =>
	defineField({
		name: 'tagline',
		title: 'Undertittel',
		type: 'string',
		group: 'content',
	});

const persons = () =>
	defineField({
		name: 'persons',
		title: 'Personer',
		description: 'Personene som skal vises i personlisten.',
		type: 'array',
		group: 'content',
		of: [{type: 'reference', to: [{type: 'person'}]}],
		validation: (Rule: Rule) => Rule.required(),
	});

export type PersonListSelectionSchema = Output<typeof personListSelection> & {
	_type: 'personListSelection';
};
