import {AiOutlineFundProjectionScreen} from 'react-icons/ai';
import type {Rule} from 'sanity';
import type {Output} from 'sanity-typed-schema';
import {defineField, defineType} from 'sanity-typed-schema';

import {
	BULLET,
	CALL_TO_ACTION,
	EMPHASIS,
	H2,
	H3,
	H4,
	H5,
	H6,
	IMAGE,
	LINK,
	NUMBERED,
	portableText,
	STRONG,
	UNDERLINE,
} from '@/sanity/components/portable-text/schema';
import {ALT_TEXT, imageWeb} from '@/sanity/schemas/primitives/image-web';
import {meta} from '@/sanity/schemas/primitives/meta';
import {PROJECTS_PAGE_PATH} from '@/shared/constants';

export const project = () =>
	defineType({
		name: 'project',
		type: 'document',
		title: 'Prosjekt',
		icon: AiOutlineFundProjectionScreen,
		fields: [
			title(),
			slug(),
			description(),
			image(),
			startDate(),
			endDate(),
			content(),
			projectLeader(),
			relevantDocuments(),
			meta(),
		],
		preview: {
			select: {
				title: 'title',
				slug: 'slug',
				image: 'image',
			},
			prepare: (select) => ({
				title: select.title,
				subtitle: `${PROJECTS_PAGE_PATH}/` + (select.slug?.current ?? '...'),
				media: select.image?.asset ? select.image : AiOutlineFundProjectionScreen,
			}),
		},
	});

const title = () =>
	defineField({
		name: 'title',
		type: 'string',
		title: 'Tittel',
		description: 'En kort og beskrivende tittel som gir et innblikk i prosjektets formål.',
		validation: (Rule: Rule) => Rule.required(),
	});

const slug = () =>
	defineField({
		name: 'slug',
		type: 'slug',
		title: 'Slug',
		description:
			'Siste del av URLen til denne siden på nettstedet. Slug bør formatteres med små bokstaver og uten spesialtegn. Det enkleste er å generere denne basert på sidens tittel.',
		options: {
			source: 'title',
		},
		validation: (Rule: Rule) =>
			Rule.custom((param?: {current: string}) => {
				if (!param) {
					return 'Obligatorisk';
				}
				if (param.current?.includes(' ') || param.current?.includes('/')) {
					return 'Slug kan ikke inneholde mellomrom eller skråstreker';
				}
				return true;
			}),
	});

const description = () =>
	defineField({
		...portableText({
			annotations: [LINK],
			styles: [],
			lists: [],
			decorators: [STRONG, EMPHASIS, UNDERLINE],
			customTypes: [],
		}),
		name: 'description',
		title: 'Prosjektbeskrivelse',
		description: 'En kort beskrivelse som oppsummerer hva prosjektet handler om.',
	});

const image = () =>
	defineField({
		...imageWeb({fields: [ALT_TEXT]}),
		description:
			'Et bilde som representerer innholdet i prosjektet. Brukes gjerne sammen med prosjektbeskrivelsen i listevisninger.',
	});

const startDate = () =>
	defineField({
		name: 'startDate',
		type: 'date',
		title: 'Startdato',
		description: 'Når prosjektet startet.',
		validation: (Rule: Rule) => Rule.required(),
	});

const endDate = () =>
	defineField({
		name: 'endDate',
		type: 'date',
		title: 'Sluttdato',
		description: 'Når prosjektet ble avsluttet.',
	});

const content = () =>
	defineField({
		...portableText({
			annotations: [LINK],
			styles: [H2, H3, H4, H5, H6],
			lists: [BULLET, NUMBERED],
			decorators: [STRONG, EMPHASIS, UNDERLINE],
			customTypes: [CALL_TO_ACTION, IMAGE],
		}),
		name: 'content',
		title: 'Brødtekst',
		description: 'En lengre tekst som utgjør hovedinnholdet i denne artikkelen.',
	});

const projectLeader = () =>
	defineField({
		name: 'projectLeader',
		type: 'reference',
		to: [{type: 'person' as const}],
		title: 'Prosjektleder',
		description: 'Personen som er ansvarlig for prosjektet.',
	});

const relevantDocuments = () =>
	defineField({
		name: 'relevantDocuments',
		type: 'array',
		of: [relevantDocument()],
		title: 'Dokumenter',
		description: 'Dokumenter som er relevante for prosjektet.',
	});

const relevantDocument = () =>
	defineType({
		name: 'relevantDocument',
		title: 'Dokument',
		type: 'object',
		fields: [documentTitle(), file()],
	});

const documentTitle = () =>
	defineField({
		name: 'documentTitle',
		type: 'string',
		title: 'Tittel',
		description: 'En kort og beskrivende tittel som gir et innblikk i dokumentets innhold.',
		validation: (Rule: Rule) => Rule.required(),
	});

const file = () =>
	defineField({
		name: 'file',
		type: 'file',
		title: 'Fil',
		description: 'Et dokument som er relevant for prosjektet.',
		validation: (Rule: Rule) => Rule.required(),
	});

export type ProjectSchema = Output<typeof project>;
